import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { isNumber } from 'lodash'
import { useStore } from 'vuex'
import { groupUserUseCase, reportSettingUserGroupUseCase } from '@/domain/usecase'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast, $strInd, $confirm } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = ref([]) as any
    const listGroup = ref([]) as any
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['title'],
      custom: [] as any
    }

    const getAllData = async () => {
      const response = await reportSettingUserGroupUseCase.getAll(route.params.id)
      console.log()
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        // const options = response.result.map((d: any) => d.GroupUserId)
        const options = response.result
        data.value = options
      }
    }

    const getListGroup = async () => {
      store.dispatch('showLoading')
      await getAllData()
      const response = await groupUserUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        filters.totalRecords = response.result.Count
        const options = response.result.Data.map((d: any) => {
          const obj = data.value.find((element: any) => element.GroupUserId === d.Id)
          d.label = d.Nama
          d.value = d.Id
          d.IsActive = !!obj
          d.AuthId = obj ? obj.Id : null
          return d
        })
        listGroup.value = options
      }
      store.dispatch('hideLoading')
    }

    const redirectBack = () => {
      router.go(-1)
    }
    const submitProcess = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        ReportId: route.params.id,
        GroupIds: data.value,
      }
      const response = await reportSettingUserGroupUseCase.submitData(dataForm)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        redirectBack()
      }
      store.dispatch('hideLoading')
    }
    const submitData = () => {
      submitProcess()
    }
    const activateAct = async (val: any) => {
      store.dispatch('showLoading')
      let response
      if (val.IsActive) {
        const payload = {
          groupUserId: val.Id
        }
        response = await reportSettingUserGroupUseCase.addReportAuth(route.params.id, payload)
      } else {
        response = await reportSettingUserGroupUseCase.deleteReportAuth(route.params.id, val.AuthId)
      }
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        getListGroup()
        $toast.add({
          severity: 'success', detail: 'Data Berhasil Diubah', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const changeIsActive = async (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          activateAct(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getListGroup()
    }

    onMounted(() => {
      // getAllData()
      getListGroup()
    })

    return {
      data,
      listGroup,
      redirectBack,
      submitData,
      changeIsActive,
      filters,
      onPage
    }
  }
}
